#maintenancePageBody {
  margin:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#logo {
  width: 200px;
  height: 200px;
  border-radius: 20px;
  margin: auto;
  margin-top: 3rem;
}
