
.linkButtonContainer {
  display: flex;
  // background-color: var(--gt-panel-color);
  height: var(--gt-row-height);
  // margin-left: 0.5rem;
  border-radius: var(--gt-border-radius);

}

.linkButton {
  padding: 1rem;
  // font-weight: bold;
}