
#tagRow {
  /* align-items: flex-end; */
}

#tagName{
  padding-left: 0.5rem;
}

#tagCount {
  padding-left: 1rem;
  color: rgba(var(--gt-secondary-font-color-rgb), 0.5);
  vertical-align: text-top;
  /* align-self: center; */
}

#topContainer {
  margin: 0.5rem 0.5rem 0 0.5rem;
}

/* #tagsList::-webkit-scrollbar{
  background-color: transparent;
  width: 22px;
  border: calc(1px / var(--f7-device-pixel-ratio)) solid rgba(0,0,0,4);
} */
