#patreonButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(254, 66, 76);
  border-radius: 42px;
  padding: 0 1.5rem 0 1.5rem;
  font-weight: 600;
  height: 50px;
  margin-bottom: 1.5rem;
}

#patreonLogo {
  padding-right: 0.75rem;
  height: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}

#paypalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 156, 221);
  border-radius: 42px;
  padding: 0 1.5rem 0 1.5rem;
  font-weight: 600;
  height: 38px;
  margin-bottom: 1.5rem;
}

#paypalLogo {
  /* padding-right: 0.75rem; */
  height: 18px;
}

#bmacButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 42px;
  padding: 0 1.5rem 0 1.5rem;
  font-weight: 600;
  height: 38px;
  margin-bottom: 2.5rem;
}

#bmacLogo {
  /* padding-right: 0.75rem; */
  border-radius: 2px;
  height: 60px;
}

#kiwanuka {
    border-radius: 0px;
    height: 62px;
}

#kiwanukaPanel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 10px;
    padding: 0 1.0rem 0 1.0rem;
    font-weight: 600;
    /* height: 60px; */
    margin-bottom: 1.5rem;
  }
  

#bitcoinLogo {
  height: 20px;
  margin: 0.25rem;
  margin-bottom: 0.1rem;
  margin-top: 0;
}

#nanoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 52, 0.9);
  border-radius: 42px;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

#nanoLogo {
  height: 12px;
}

#donateButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
