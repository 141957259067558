#homePageBody {
  margin:auto;
  display: flex;
  flex-direction: column;
}

#logo {
  width: 256px;
  height: 256px;
  border-radius: 20px;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

#AppleLogin {
  margin: auto;
}

.signin-button {
  width: 210px;
  height: 40px;
}