.rootContainer {
  margin: 0.5rem;
  margin-bottom: 0rem;
}

#chip {
  padding: 0;
  margin: 0;
  margin-top: 0.5rem;
  margin-right: 0.25rem;
}

#link {
  font-size: var(--gt-primary-font-size);
  padding: 1rem;
}

