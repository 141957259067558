:root {
  --gt-border-radius: 10px;
  --gt-row-height: 44px;

  --f7-font-size: 16px;

  --gt-primary-font-size: calc(var(--f7-font-size));
  --gt-secondary-font-size: calc(0.75 * var(--f7-font-size));
  --gt-mini-font-size: calc(0.65 * var(--f7-font-size));
  --gt-rating-font-size: calc(1 * var(--f7-font-size));

  --gt-secondary-font-color-rgb: 125, 125, 125;
  --gt-secondary-font-color: rgb(var(--gt-secondary-font-color-rgb));

  --gt-icon-size: 1rem;
  --gt-icon-size-medium: 1.3rem;
  --gt-icon-size-large: 1.5rem;

  --f7-page-push-transition-duration: 300ms;
  --f7-page-parallax-transition-duration: 300ms;
  --f7-page-dive-transition-duration: 200ms;
  --f7-page-fade-transition-duration: 200ms;

  --f7-panel-width: 290px;

  --f7-popup-tablet-width: 630px;
  --f7-popup-tablet-height: 630px;

  // --f7-list-bg-color: rgba(0,0,0,0);

  //   --f7-panel-backdrop-bg-color: rgba(0, 0, 0, 0.3);
}

.color-theme-blue {
  --f7-theme-color: #00a1de;
  --f7-theme-color-rgb: 0, 161, 222;
  --f7-theme-color-shade: #0e92c1;
  --f7-theme-color-tint: #16b9f4;
  --f7-list-chevron-icon-color: var(--f7-theme-color);
}

.color-theme-orange {
  --f7-theme-color: #e67e22;
  --f7-theme-color-rgb: 230, 126, 34;
  --f7-theme-color-shade: #c0600d;
  --f7-theme-color-tint: #ff963a;
  --f7-list-chevron-icon-color: var(--f7-theme-color);
}

html,
body {
  position: fixed;
  overflow: hidden;
  scrollbar-width: thin;
}

html {
  display: flex;
  justify-content: center;
  background-color: rgb(175, 182, 189);
}

// $mb-font: "Helvetica";
// @import url("https://fonts.googleapis.com/css?family=#{$mb-font}");

body {
  max-width: 1280px;
  //   font-family: $mb-font;
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.3);
}

.button {
  text-transform: none;
}

.popover-backdrop {
  transition-duration: 200ms;
  background-color: rgba(0, 0, 0, 0.15);
}

.ios,
.aurora {
  --gt-panel-color-rgb: 255, 255, 255;
  --gt-panel-color: rgb(var(--gt-panel-color-rgb));
  --f7-navbar-height: var(--gt-row-height);
  --f7-navbar-tablet-height: var(--gt-row-height);
}

.theme-dark {
  --gt-panel-color-rgb: 31, 34, 37;
  --gt-panel-color: rgb(var(--gt-panel-color-rgb));
}

:root {
  --f7-navbar-bg-color: rgb(255, 255, 255);
  --f7-subnavbar-bg-color: rgb(255, 255, 255);
  --f7-navbar-title-font-weight: 500;
  // --f7-block-title-margin-bottom: 0px;
}

// ::-webkit-scrollbar{
//   background-color: transparent;
//   width: 4px;
// }

// ::-webkit-scrollbar-track{
//   background-color: transparent;
// }

// ::-webkit-scrollbar-track-piece{
//   background-color: transparent;
// }

// ::-webkit-scrollbar-thumb{
//   background-color: rgb(175,175,175);
//   border-radius: 6px;
// }

::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
  // border-left: calc(1px / var(--f7-device-pixel-ratio)) solid rgba(0,0,0,0.05);
}

::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.175);
}

#marcobotRightPanel {
  border-left: calc(1px / var(--f7-device-pixel-ratio)) solid rgba(0, 0, 0, 0.125);
  // box-shadow: 0 0 64px rgba(0,0,0,0.1);
  ::-webkit-scrollbar {
    border-left: calc(1px / var(--f7-device-pixel-ratio)) solid rgba(0, 0, 0, 0.125);
  }
}

#marcobotLeftPanel {
  border-right: calc(1px / var(--f7-device-pixel-ratio)) solid rgba(0, 0, 0, 0.125);
  // box-shadow: 0 0 64px rgba(0,0,0,0.1);
}

@font-face {
  font-family: "Vectrex";
  src: local("Vectrex"), url(../fonts/Vectrex.ttf) format("truetype");
}

// const effects = [
//   'f7-circle',
//   'f7-cover',
//   'f7-cover-v',
//   'f7-dive',
//   'f7-fade',
//   'f7-flip',
//   'f7-parallax',
//   'f7-push',
// ];
