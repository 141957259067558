#sp-pageRoot {
  // background-color: var(--gt-panel-color);
  overflow-x: hidden;
  margin: auto;
  max-width: 840px;


  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */
  // @supports (-webkit-touch-callout: none) {
  //   margin-right: 0px;
  // }  
}

// #sp-pageRoot::-webkit-scrollbar{
//   background-color: transparent;
//   width: 12px;
//   border: calc(1px / var(--f7-device-pixel-ratio)) solid rgba(0,0,0,0.05);
// }

// #sp-pageRoot::-webkit-scrollbar-thumb{
//   border: 3px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box; 
//   border-radius: 20px;
//   background-color: rgba(0, 0, 0, 0.175);
// }


// #pageRoot::-webkit-scrollbar{
//   width: 14px;
//   height: 18px;
// }

// #pageRoot::-webkit-scrollbar-track{
//   background-color: transparent;
// }

// #pageRoot::-webkit-scrollbar-track-piece{
//   background-color: transparent;
// }

// #pageRoot::-webkit-scrollbar-thumb{
//   height: 6px;
//   border: 4px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   background-color: rgba(0, 0, 0, 0.2);
//   -webkit-border-radius: 7px;
//   -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
//     inset 1px 1px 0px rgba(0, 0, 0, 0.05);
// }

// #listConte

// #listContent {
//   padding-right: -4px;
//   @supports (-webkit-touch-callout: none) {
//     padding-right: 0px;
//   }  
// }
 
 